/** @format */

import {
    AmountConst,
    DurationConst,
    DurationTitleConst,
    MonthlyPriceConst,
} from '../../../utlis/constants/PlansConstants';
import { setPlan } from '../../mainPage/plansSlice';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

export default function PriceCard({ duration }) {
    const dispatch = useDispatch();
    const btnRef = useRef();

    const getDescription = () => {
        switch (duration) {
            case DurationConst.MONTH_1:
                return 'базовый тариф';
            case DurationConst.MONTH_6:
                return 'немного, но выгоднее';
            case DurationConst.MONTH_12:
                return (
                    <>
                        сааааамый выгодный, <br /> для настоящих профи
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="price_card">
            <div className="price_title">
                <h1>{DurationTitleConst[duration]}</h1>
                <p>{getDescription()}</p>
            </div>
            <div className="price_info">
                <h1>{AmountConst[duration]}</h1>
                <p>
                    российских <br /> рублей
                </p>
            </div>
            <p className="monthly_price">
                {duration === DurationConst.MONTH_1 ? (
                    <br />
                ) : (
                    `≈${MonthlyPriceConst[duration]} ₽ / месяц`
                )}
            </p>
            <button
                onClick={() => {
                    dispatch(setPlan(duration));
                    btnRef.current.click();
                }}
            >
                Оформить подписку
            </button>
            <Link hidden ref={btnRef} to={`/auth/${duration}`} />
        </div>
    );
}
