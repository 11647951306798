/** @format */
import { DurationConst } from '../../../utlis/constants/PlansConstants';
import PriceCard from './PriceCard';

export default function Plans() {
    return (
        <>
            {/* <h1 className="text text5">
                выберите <br /> свой удобный план
            </h1> */}
            <div className="price_container">
                <PriceCard duration={DurationConst.MONTH_1} />
                <PriceCard duration={DurationConst.MONTH_6} />
                <PriceCard duration={DurationConst.MONTH_12} />
            </div>
        </>
    );
}
