/** @format */

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = 'FHB | ' + title;
        return () => {
            document.title = prevTitle;
        };
    });
}

export function useAnchorScroll() {
    const { hash, pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) element.scrollIntoView({ behavior: 'smooth' });
            navigate(pathname);
        }
    }, [hash]);
}
