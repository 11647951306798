/** @format */

import PaymentHistoryTable from './components/PaymentHistoryTable';
import LoginButton from './components/LoginButton';
import UserSubsciption from './components/UserSubsciption';
import { useEffect } from 'react';
import { userAPI } from '../../config/api.ts';
import { useTitle } from '../../utlis/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileInfo } from './userSlice';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header.jsx';

export default function UserPage() {
    useTitle('User');
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.id) navigate('/auth');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user?.id) {
            userAPI.subscribersPayments({ userId: user.id }).then((res) => {
                if (res?.payment_order_history && res?.active_subscription && res?.next_payment_date)
                    dispatch(
                        setProfileInfo({
                            paymentOrderHistory: res.payment_order_history,
                            activeSubscription: res.active_subscription,
                            nextPaymentDate: res.next_payment_date,
                        })
                    );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return user?.id ? (
        <div className="account_page">
            <Header />
            <div className="top_row">
                <UserSubsciption />
                <LoginButton />
            </div>
            <div className="bottom_row">
                <PaymentHistoryTable />
            </div>
        </div>
    ) : (
        <></>
    );
}
