/** @format */ import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { useTitle } from '../../utlis/hooks';
import { useDispatch } from 'react-redux';
import { AmountConst } from '../../utlis/constants/PlansConstants';
import { paymentAPI } from '../../config/api.ts';
import { login } from './userSlice';
import { getPayloadState } from '../../utlis/utils';
import LoginForm from './components/LoginForm';
import Header from '../../components/Header';
import { VKSettings } from '../../utlis/constants/VKSettings';

export default function LoginPage() {
    useTitle('Auth');
    const { search } = useLocation();
    const [vkResponse, setVkResponse] = useState([null, null]);
    const { duration } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (search) setVkResponse(getPayloadState(search));
    }, [search]);

    useEffect(() => {
        const [payload, state] = vkResponse;
        if (payload && state) {
            if (state?.duration) {
                setLoading(true);
                paymentAPI
                    .createPayment({
                        userId: payload.user.id,
                        amount: AmountConst[state.duration],
                        duration: state.duration,
                        uuid: payload.uuid,
                        token: payload.token,
                        serviceToken: VKSettings.serviceToken,
                        phone: state?.phone,
                        email: state?.email,
                    })
                    .then((res) => {
                        setLoading(false);
                        if (res?.payment_url)
                            window.location.href = res?.payment_url;
                    });
            } else {
                dispatch(
                    login({
                        id: payload.user.id,
                        avatar: payload.user.avatar,
                        firstName: payload.user.first_name,
                        lastName: payload.user.last_name,
                        token: payload.token,
                        uuid: payload.uuid,
                        phone: payload.user.phone,
                    })
                );
                navigate('/user');
            }
        }
    }, [vkResponse, dispatch, navigate]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Spin: {
                        contentHeight: '100%',
                    },
                },
            }}
        >
            <Spin size="large" spinning={loading}>
                <Header />
                <LoginForm duration={duration} />
            </Spin>
        </ConfigProvider>
    );
}
