/** @format */

import React from 'react';
import {
    DurationTitleConst,
    MonthlyPriceTitleConst,
} from '../../../utlis/constants/PlansConstants';
import { useSelector } from 'react-redux';
import { userAPI } from '../../../config/api.ts';
import { useNavigate } from 'react-router-dom';

export default function UserSubsciption() {
    const activeSubscription = useSelector(
        (state) => state.user.activeSubscription
    );
    const nextPaymentDate = useSelector((state) => state.user.nextPaymentDate);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    return (
        <div className="user_subscription_container">
            <h3>Подписка</h3>
            <h4>
                {activeSubscription?.duration
                    ? `На ${DurationTitleConst[activeSubscription?.duration]}`
                    : 'Отсутствует'}
            </h4>
            {activeSubscription?.amount && (
                <p className="subscription_price">
                    {activeSubscription?.amount} ₽{' '}
                    {MonthlyPriceTitleConst[activeSubscription?.duration]}
                </p>
            )}
            <div className="buttons">
                {activeSubscription?.duration ? (
                    <>
                        <button
                            className="changebtn"
                            onClick={() => navigate('/#plans')}
                        >
                            Изменить
                        </button>
                        <button
                            className="cancelbtn"
                            onClick={() => {
                                userAPI.rollbackPayment({ userId: user?.id });
                            }}
                        >
                            Отменить
                        </button>
                    </>
                ) : (
                    <button
                        className="changebtn"
                        style={{ marginTop: '50px', width: '100%' }}
                        onClick={() => navigate('/#plans')}
                    >
                        Оформить подписку
                    </button>
                )}
            </div>
            {nextPaymentDate && (
                <p className="next_payment">
                    Следующее списание произойдет {nextPaymentDate}
                </p>
            )}
        </div>
    );
}
