/** @format */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {
        id: null,
        avatar: null,
        firstName: null,
        lastName: null,
        token: null,
        uuid: null,
        phone: null,
    },
    paymentOrderHistory: [],
    activeSubscription: {
        duration: null,
        amount: null,
    },
    nextPaymentDate: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: (state = initialState, action) => {
            return initialState;
        },
        setProfileInfo: (state, action) => {
            state.paymentOrderHistory = action.payload.paymentOrderHistory;
            state.activeSubscription = action.payload.activeSubscription;
            state.nextPaymentDate = action.payload.nextPaymentDate;
        },
    },
});

export const { login, logout, setProfileInfo } = userSlice.actions;

export default userSlice.reducer;
