/** @format */
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/');
    };
    const location = useLocation();

    return (
        <header>
            <img
                src="/logo.svg"
                alt="logo"
                onClick={onClick}
            />
            <h1 onClick={onClick}>
                Flight Helper Bot
            </h1>
            {location.pathname === '/' && (
                <button className="intro-button header-button">
                    <Link to="/user">Войти в ЛК</Link>
                </button>
            )}
        </header>
    );
}
