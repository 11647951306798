/** @format */

import { message } from 'antd';

export const getPayloadState = (str) => {
    const [payload, state] = str.replace(/&quot;/g, '"').split('&state=');
    return [
        JSON.parse(decodeURIComponent(payload.replace('?payload=', ''))),
        JSON.parse(decodeURIComponent(state)),
    ];
};

export const printError = (error) => {
    if (error?.code === 'ERR_NETWORK') return message.error(error?.message);
    if (error?.response?.data?.message)
        return message.error(error?.response?.data?.message);
};
