/** @format */

export default function MarketingText() {
    return (
        <div className="text_container">
            <h1 className="text text1">
                ...и о самом, <br /> самом главном.
            </h1>
            <h1 className="text text2">дешёво</h1>
            <h1 className="text text3">функционально</h1>
            <h1 className="text text4">без проблем с оплатой</h1>
        </div>
    );
}
