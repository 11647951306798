/** @format */

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import MainPage from '../mainPage/MainPage';
import Offer from '../offer/Offer';
import Policy from '../policy/Policy';
import AppLayout from '../../components/AppLayout';
import LoginPage from '../user/LoginPage';
import UserPage from '../user/UserPage';
import { Provider } from 'react-redux';
import { store } from '../../config/store.ts';
import { FloatButton } from 'antd';
import Game from '../Game';
import PLansPage from '../plans/PlansPage.jsx';
import ErrorPage from '../../components/ErrorPage';

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Suspense fallback={<h1>Loading...</h1>}>
                    <FloatButton.BackTop />
                    <Routes>
                        <Route path="/offer" element={<Offer />} />
                        <Route path="/policy" element={<Policy />} />
                        <Route path="/auth/:duration" element={<LoginPage />} />
                        <Route path="/auth" element={<LoginPage />} />
                        <Route path="/game" element={<Game />} />
                        <Route path="/user" element={<UserPage />} />
                        <Route
                            path="/chart-limit"
                            element={
                                <AppLayout>
                                    <PLansPage />
                                </AppLayout>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <AppLayout>
                                    <MainPage />
                                </AppLayout>
                            }
                        />
                        <Route path="*" element={<ErrorPage code="404" />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
