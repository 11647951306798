/** @format */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVk } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import * as VKID from '@vkid/sdk';
import img1 from '../../../static/images/side_image1.jpg';
import img2 from '../../../static/images/side_image2.jpg';
import img3 from '../../../static/images/side_image3.jpg';
import img4 from '../../../static/images/side_image4.jpg';
import img5 from '../../../static/images/side_image5.jpg';
import img6 from '../../../static/images/side_image6.jpg';
import img7 from '../../../static/images/side_image7.jpg';
import { VKSettings } from '../../../utlis/constants/VKSettings';

export default function LoginForm({ duration = null }) {
    const [currentImage, setCurrentImage] = useState('');
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const { pathname } = useLocation();

    const images = [img1, img2, img3, img4, img5, img6, img7];

    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
    };

    useEffect(() => {
        const randomImage = getRandomImage();
        setCurrentImage(randomImage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="login_form_container">
                <form className="login_form" action="">
                    <h1>
                        Авторизуйтесь, <br /> чтобы мы вас <br /> узнали
                    </h1>
                    <button
                        id="VKIDSDKAuthButton"
                        className="vk_login"
                        onClick={(e) => {
                            e.preventDefault();
                            VKID.Config.set({
                                app: VKSettings.appId, // Идентификатор приложения.
                                redirectUrl: VKSettings.redirectUrl, // Адрес для перехода после авторизации.
                                state: `{"duration":${duration && '"' + duration + '"'},"phone":${phone && '"' + phone + '"'},"email":${email && '"' + email + '"'}}`, // Произвольная строка состояния приложения.
                            });
                            // Открытие авторизации.
                            VKID.Auth.login();
                        }}
                    >
                        <FontAwesomeIcon className="vk_icon" icon={faVk} />
                        <p>Войти через ВКонтакте</p>
                    </button>
                    <p>
                        Продолжая авторизацию, вы соглашаетесь с{' '}
                        {<Link to="/offer">офертой</Link>} и <br />
                        {<Link to="/policy">политикой конфиденциальности</Link>}
                    </p>
                    {pathname !== '/auth' && (
                        <>
                            <p>
                                укажите Email, если вы хотите получить чек об оплате
                            </p>
                            <div className="email_input">
                                <label htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            {/* <div className="phone_input">
                                <label htmlFor="phone">
                                    Телефон (необязательно)
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                    }}
                                />
                            </div> */}
                        </>
                    )}
                </form>
            </div>
            <div className="side_image">
                <img src={currentImage} alt="side img" />
            </div>
        </>
    );
}
